.plants-by-location-container {
    margin: 15px;
    padding: 15px;
    min-height: 500px;
}

.plants-by-location-section {
    background-color: whitesmoke;
    min-height: 400px;
    margin: 15px;
    padding: 15px;
}

.color-coding-details-section {
    text-align: left;
}

div.color-coding-details {
    display: inline-block;
    width: 35px;
    text-align: center;
    font-weight: 300;
}

span.plant-details-label {
    font-size: 21px;
    font-weight: 400;
}

