
body {
    background: #000e21;
    background-image: linear-gradient(314deg, #51713a 0%, #000e21 0%);
}

span.image-gallery-description {
    /* color: red!important; */
    top: 30px!important;
    left: 70px!important;
    /* bottom: none!important; */
    /* padding: 0px!important; */
    position: fixed!important;
    font-size: x-large;
    background: rgba(0, 0, 0, 1);
}

.slider-section {
    padding: 20px 10px;
}

.newplant-section, .forecastplants-section, .watersnip-section {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.home-section {
    background-color: #000e21;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-top: 115px;
    margin-bottom: 115px;

    min-height: 500px;
    /* border: 3px solid grey; */
    /* box-shadow: 6px 8px rgba(0, 0, 0, 0.4); */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: fit-content(1em);
    grid-template-areas:
        "header header header"
        "plant-card-section plant-card-section plant-card-section";
    align-content: center;
    overflow: hidden;
}
  
.plant-card-section {
    grid-area: plant-card-section;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
    position: relative;
    padding-top: 0px;
    margin-top: 0px;
}

.home-section-header {
    grid-area: header;
    color: whitesmoke;
    text-align: left;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    background-color:  rgba(50, 50, 50, 0.8);
    margin-bottom: 0px;
    font-weight: 300;
}
