 *, ::after, ::before {
    box-sizing: border-box;
}

html, body {
    height: 100%;
} 

.plant-details-section {
    /* background-color: #000e21; */
    background-color: white;
    padding-top: 0px;
    padding-bottom: 20px;
    /* margin-top: 115px; */
    margin-bottom: 115px;
    padding-left: 50px;
    padding-right: 50px;

    min-height: 500px;
    /* border: 3px solid grey; */
    /* box-shadow: 6px 8px rgba(0, 0, 0, 0.4); */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: fit-content(1em);
    grid-template-areas:
        "description description"
        "plant-specific-data plant-specific-data"
        "instance-specific-data instance-specific-data"
        "plant-details-watering plant-details-watering" ;
    align-content: center;
    overflow: hidden;
}
  
.plant-details-header {
    grid-area: description;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(325px, 1fr)); */
    /* position: relative; */
    /* padding-top: 0px;
    margin-top: 0px; */
    background-color: white;
    padding-bottom: 50px;
}

.plant-specific-data {
    grid-area: plant-specific-data;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(325px, 1fr)); */
    /* position: relative;
    padding-top: 0px;
    margin-top: 0px; */
    /* background-color: white; */
    padding-bottom: 50px;
}

.instance-specific-data {
    grid-area: instance-specific-data;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(325px, 1fr)); */
    /* position: relative;
    padding-top: 0px;
    margin-top: 0px; */
    /* background-color: white; */
    padding-bottom: 50px;
}

.plant-details-watering {
    grid-area: plant-details-watering;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(325px, 1fr)); */
    /* position: relative;
    padding-top: 0px;
    margin-top: 0px; */
    /* background-color: white; */
}

img.plant-details-imgurl {
    margin-left: 20px;
}

.plant-details-name, .plant-details-botanical {
    /* padding: 20px; */
    font-weight: 600;
    text-align: left;
}

.plant-details-botanical {
    font-size: 40px;
    font-style: italic;
    border: none;
}

.plant-details-name {
    font-size: 50px;
    border: none;
}

.plant-card-details-group, .button-section-header {
    text-align: left;
}

.plant-card-details {
    /* list-style-type: none; */
    /* border-bottom: 0.03em solid lightgrey; */
    /* word-wrap: break-word; */
    /* border-style: inset; */
    /* opacity: 0.5; */
}

.water-button, .plant-details-overview-button {
    border-radius: 4px; 
    padding: 5px;
    min-width: 135px;
    height: 40px;
}

button.always {
    /* display: none; Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 100px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  background-color: grey; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */   
}

button.always:hover {
    background-color: lightgrey;
}

.plant-details-label {
    /* width: 100%; */
    text-align: left;
    margin-left: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: bold;
}

.plant-details-comment {
    /* width: 100%; */
    text-align: left;
    /* margin-left: 20px; */
    padding-bottom: 0;
    padding-top: 10px;
    /* margin-bottom: 0; */
}

textarea.plant-textarea {
    /* width: 80vw; */
    width: 100%;
    height: 150px;
    /* resize: none; */
    border: none;
    margin-left: 20px;
    margin-right: 0;
    padding: 10px;
    border-bottom: 0.03em solid lightgrey;
    
}

input.plant-details {
    margin-left: 20px;
    border: none;
    /* width: 250px; */
}

input.plants-details-name {
    margin-left: 20px;
    border: none;
    /* width: 500px; */
}

select.plant-details {
    margin-left: 20px;
    border: none;
}
/* 
//need this if rendering in-line
//commented out now that I'm using a textarea
.plant-description {
    white-space: pre-wrap;
} */

/* .plant-details-section {
    margin-left: 20px;
    text-align: left;
} */

.plant-details-group {
    display:grid;
    grid-template-columns: max-content 1fr;
    grid-gap:5px;
    padding-bottom: 20px;
}

.plant-details-label {
    text-align: right;
}

.plant-details-label:after {
    content: ":";
}
/* .plant-details-left {
    width: 40vw;
    margin-right: 50px;
}

.plants-details-right {
    width: 40vw;
    margin-right: 50px;
} */

.color-coding {
    text-align: left;
    margin-bottom: 10px;
    /* justify-content: start; */
}

.color-coding-details {
    margin: 0;
}

.button-section {
    margin-left: 20px;
    text-align: left;
}

.button-submitted {
    background-color: green;
}

.button-not-submitted {
    background-color: #3A6C8F;
}

div.lastWatered {
    width: 300px;
}

input.plant-details-specific {
    width: 200px;
    border: none;
}

input.wdbc {
    border: none;
}

input.plant-details-watering {
    border:none;
    margin: 5px;
    width: 140px;
}

.plant-details-section-header {
    text-align: left;
}

textarea {
    width: 100%;
}

.plant-specific-view {
    /* display:block; */
    text-align: left;
}

.plant-specific-edit {
    /* display:none; */
}

.plant-details-visible {
    display:block;
    
}

.plant-details-hidden {
    display:none;
}

.plant-detail-editing {
    background-color: whitesmoke;
}

.plant-detail-not-editing {
}

.heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plant-details-overview-button {
    
}