
.plant-block-section {
  /* background-color: #000e21; */
  padding: 8px;
  margin-top: 50px;
  margin-bottom: 20px;

  min-height: 500px;
  /* border: 3px solid grey; */
  /* box-shadow: 6px 8px rgba(0, 0, 0, 0.4); */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: fit-content(1em);
  grid-template-areas:
    "header header header"
    "mostrecent mostrecent mostrecent";
  align-content: center;
  overflow: hidden;
}

.recent-plant-header {
  grid-area: header;
}

.plant-block-plant {
  grid-area: mostrecent;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  
}
.plant-block-wrapper {
  position: relative;
}

.plant-block {
  border-radius: 2px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: grid;
  height: 400px;
  overflow: hidden;
  opacity: 1;
  z-index: 1;
  position: relative;
}



/* .plant-block:hover {
  -webkit-transform: scale(1, 1);
  transform: scale(1.03, 1.03);
  opacity: 1;
  z-index: 5;
  cursor: pointer;
} */

.plant-title-img {
  position: absolute;
  background-color: cadetblue;
  padding: 5px;
  z-index: 10;
}

.plant-block-2 {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  right:0;
  opacity: 0;
  position: absolute;
  z-index: 2;
  height: 400px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
}

.plant-block-2:hover {
  opacity: 1;
  z-index: 5;
  color: whitesmoke;
  justify-content: center;
}