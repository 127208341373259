
.plant-card-container {
  position: relative;
}

/* this is for the div surrounding the image */
.plant-card-img-wrapper .plant-card-spotlight-img-wrapper {
  border-radius: 2px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;
  opacity: 1;
  z-index: 1;
  position: relative;
}

.plant-card-img-wrapper {
  width: 100%;
  height: 400px;
}

.plant-card-spotlight-img-wrapper {
  height: 600px;
}

/* this is for the image itself */
.plant-card-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.plant-card-text-overlay {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  right:0;
  opacity: 0;
  position: absolute;
  z-index: 2;
  height: 400px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
}



.plant-card-text-overlay:hover {
  opacity: 1;
  z-index: 5;
  color: whitesmoke;
  justify-content: center;
}


.fa-ex1 {
  justify-items: flex-end;
}

.plant-card-spotlight-overlay {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  right:0;
  opacity: 0;
  position: absolute;
  z-index: 2;
  height: 600px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
}


.plant-card-spotlight-overlay:hover {
  opacity: 1;
  z-index: 5;
  color: whitesmoke;
  justify-content: center;
}

.plant-card-spotlight-text {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  right:0;
  opacity: 1;
  position: relative;
  z-index: 1;
  height: 600px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 30px;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
  color: white;
}
