.forecast-plant-hardiness-section {
    background-color: lightgrey;
    /* margin-top: 80px; */
    box-shadow: 6px 8px rgba(0, 0, 0, 0.4);
    /* border-radius: 30px; */
    display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: fit-content(1em);
}

.weather-icon {
    width: 60px;
    height: 60px;
}

.low-temp {
    color: gray;
}

.forecast-options, .forecast-options-today, .forecast-options-next {

}

.forecast-options-today {
    font-size: 50px;
}

.forecast-weather {
    margin: 10px;
}

.forecast-alloutdoor {
    display: flex;
    justify-content: space-between;
}


.outdoor-plants-number {
    font-size: 20px;
}

h3.forecast-hardiness-header {
    display: flex;
    color: whitesmoke;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    background-color:  rgba(50, 50, 50, 0.8);
    margin-bottom: 0px;
    font-weight: 300;
    justify-content: center;
  }

  .forecast-plants {
      font-size: 12px;
  }