.more-plants-card {
  position: relative;
}

.more-plant-card {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  right:0;
  height: 400px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 21px;
  font-weight: 300;
  cursor: pointer;
  color: white;
}

.more-plant-card-text {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.more-plant-card-img {
  opacity: 0;
  position: absolute;
  z-index: 2;
}

.more-plant-card:hover {
  opacity: .3;
}

.g-t {
  font-size: 25px;
}
