.header-main, .header-sub {
    box-sizing: border-box;
    /* background: url('../../img/listwindowplants.jpg'); */
    background-size: cover;
    background-position: center;
    /* background-attachment: fixed; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: #5E635C;
    color: #fff;
    opacity: 1;
    transition: background-image 1s ease-in-out;
    -moz-transition: background-image 1s ease-in-out;
    -webkit-transition: background-image 1s ease-in-out;
    /* padding: 10px; */
}

.header-main {
    height: 570px;
}

.header-sub {
    height: 300px;
}

p.page-header::first-letter {
    text-transform: uppercase;
}

p.page-header {
    font-size: 5em;
    font-weight: bold;
    margin-right: 100px;
    text-align: right;
}


